$(() => {

	const
		userForm = $('#loginForm'),
		toggles = userForm.find('.toggleVisibility');

	toggles.on('click', (e) => {
		const
			target = e.currentTarget,
			parent = $(target).parent('.input'),
			field = parent.children('input')[0];

		parent.toggleClass('visibilityOff').toggleClass('visibilityOn');
		if (parent.hasClass('visibilityOn')) {
			field.type = 'text';
		} else {
			field.type = 'password';
		}
	});

});